import React from "react";
import { VStack, Image, Heading, Text, useMediaQuery } from "@chakra-ui/react";

const Slides = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <VStack py={{ base: 16, tablet: 32 }} w={"62%"}>
      <Text
        px={2}
        py={1}
        bg={"pippin"}
        color={"cocoaBean"}
        borderRadius={"36px"}
        fontSize={{ base: "12px", tablet: "14px" }}
      >
        Our Testimonials
      </Text>
      <Heading
        _before={{
          content: "''",
          bgImg: "/images/inverted-comma-2.svg",
          bgPos: "center",
          bgSize: isMobile ? "36px" : "142px",
          bgRepeat: "no-repeat",
          position: "absolute",
          w: "150px",
          h: "142px",
          left: isMobile ? "-72px" : "-7%",
          top: isMobile ? "-68px" : "-50px",
          zIndex: -1,
        }}
        _after={{
          content: "''",
          bgImg: "/images/inverted-comma-1.svg",
          bgPos: "center",
          bgSize: isMobile ? "36px" : "142px",
          bgRepeat: "no-repeat",
          position: "absolute",
          w: "150px",
          h: "142px",
          bottom: isMobile ? "-59px" : "-30%",
          right: isMobile ? "-90px" : "-6%",
          zIndex: -1,
        }}
        mt={4}
        textAlign={"center"}
        fontWeight={"600"}
        fontSize={{ base: "16px", tablet: "48px" }}
        position={"relative"}
      >
        the eggless cakes here are really good. Had ordered a kit kat cake which
        was really good. Surely worth a try.
      </Heading>
      <Image mt={14} src="/images/user-photo.png" borderRadius={"50%"} />
      <Heading
        mt={4}
        color={"cocoaBean"}
        fontWeight={"600"}
        fontSize={{ base: "12px", tablet: "2xl" }}
      >
        Darren Dunlap
      </Heading>
      <Text
        mt={2}
        color={"mineShaft2"}
        fontSize={{ base: "10px", tablet: "lg" }}
        fontWeight={"500"}
      >
        CEO & Founder at Flex.co
      </Text>
    </VStack>
  );
};

export default Slides;
