import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Work = () => {
  return (
    <VStack px={{base: 10, tablet: "104px"}} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Work for Social Cause</title>
      </Helmet>
      <Heading fontSize={{base: "24px", tablet: "36px"}} fontWeight={"600"}>
        Work for Social Cause
      </Heading>
      <Image mt={20} src="/images/social-work-hero.svg" alt="hero" />
      <VStack
        px={{base: 6, tablet: 16}}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          Engage, Impact, and Make a Difference with us!
        </Text>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Giving Back, One Order at a Time
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            For every order placed, we used to contribute 1% of the net order value to
            the KOSHISH EDUCATIONAL AND WELFARE SOCIETY.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Supporting Education for All
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Our partnership with the NGO aims to provide quality education to
            underprivileged individuals.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Transparency and Accountability
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          Curious about our donations? Feel free to reach out to us to learn more about it. We're committed to transparency every step of the way.
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Work;
