import { useContext } from "react";
import {
  VStack,
  Text,
  Heading,
  HStack,
  Image,
  Box,
  Flex,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { FaLocationDot } from "react-icons/fa6";
import { FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CustomerContext } from "../CustomerContext";

const Footer = () => {
  const { isCustomer } = useContext(CustomerContext);

  return (
    <VStack
      alignItems={"start"}
      w="full"
      pt={12}
      pb={6}
      gap={{base: 10, tablet: 20}}
      px={{ base: 4, tablet: 32 }}
    >
      <HStack
        justifyContent={{ base: "center", tablet: "space-between" }}
        w="full"
        alignItems={"start"}
      >
        <VStack alignItems={"start"} display={{ base: "none", tablet: "flex" }}>
          <Heading color={"vulcan"} fontSize={"18px"} fontWeight={"700"}>
            Download the app by clicking the icons below!
          </Heading>
          <HStack mt={10}>
            <Link
              to={
                isCustomer
                  ? "https://play.google.com/store/apps/details?id=com.cakelaya&hl=en_US"
                  : "https://play.google.com/store/apps/details?id=com.cakelaya.merchant"
              }
            >
              <Image
                src="/images/play-store.svg"
                alt="Play Store"
                cursor={"pointer"}
              />
            </Link>
            <Image src="/images/apple-store.svg" alt="Apple Store" />
          </HStack>
        </VStack>

        <Flex
          alignItems={{ base: "center", tablet: "start" }}
          gap={10}
          flexDir={{ base: "column", tablet: "row" }}
        >
          <VStack alignItems={{ base: "center", tablet: "start" }}>
            <Heading
              fontWeight={"700"}
              fontSize={{ base: "20px", tablet: "18px" }}
            >
              Contact Us
            </Heading>
            <VStack alignItems={{base: "center", tablet: 'start'}} mt={{base: 4, tablet: 10}} fontSize={"16px"}>
              <HStack gap={4} alignItems={"center"}>
                <EmailIcon />
                <Link to="mailto:support@cakelaya.com">
                  <Text cursor={"pointer"} fontSize={"14px"} color={"vulcan"}>
                    support@cakelaya.com
                  </Text>
                </Link>
              </HStack>
              <HStack gap={4} alignItems={"center"}>
                <FaLocationDot />
                <Text cursor={"pointer"} fontSize={"14px"} color={"vulcan"}>
                  F Block, Sector 22, Noida, U.P. (201301)
                </Text>
              </HStack>
            </VStack>
          </VStack>

          <VStack alignItems={{ base: "center", tablet: "start" }}>
            <Heading
              fontWeight={"700"}
              fontSize={{ base: "20px", tablet: "18px" }}
            >
              Social Media
            </Heading>
            <HStack mt={{base: 4, tablet: 10}} fontSize={"16px"} gap={4}>
              <Link to={"https://www.linkedin.com/company/cakelaya/"}>
                <Box cursor={"pointer"}>
                  <FaLinkedinIn />
                </Box>
              </Link>
              <Link to={"https://www.instagram.com/cakelayaa/"}>
                <Box cursor={"pointer"}>
                  <FaInstagram />
                </Box>
              </Link>
            </HStack>
          </VStack>
        </Flex>
      </HStack>

      <Text
        textAlign={{ base: "center", tablet: "left" }}
        color={"gray"}
        fontSize={"12px"}
        fontWeight={"700"}
      >
        Copyright © 2024 | ZESTEASE FOODTECH PRIVATE LIMITED ® | All Rights
        Reserved
      </Text>
    </VStack>
  );
};

export default Footer;
