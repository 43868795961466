import { useContext } from "react";
import {
  HStack,
  Text,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CustomerContext } from "../CustomerContext";

const Navbar = () => {
  const { isCustomer, setIsCustomer } = useContext(CustomerContext);

  return (
    <HStack w='full' px={'118px'} mt={'28px'} justifyContent={'space-between'}>
      <Link to="/">
        <Image
          src="/images/mainLogo.png"
          alt="Logo"
          h={{ base: "30px", md: "45px", tablet: "55px" }}
        />
      </Link>
      <HStack w={'337px'} justifyContent={'space-between'} color={'black'} mx={10}>
        <Link to="/">
          <Text>Home</Text>
        </Link>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton isActive={isOpen}>
                <HStack gap={'10px'}>
                  <Text>About</Text>
                  <ChevronDownIcon
                    transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                    transition={"transform 0.3s ease-in-out"}
                  />
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link to={"/about"}>
                    <Text>About Us</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/aim"}>
                    <Text>Aim & Vision</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/values"}>
                    <Text>Key Values</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/work"}>
                    <Text>Work for Social Cause</Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton isActive={isOpen}>
                <HStack gap={'10px'}>
                  <Text>Policy</Text>
                  <ChevronDownIcon
                    transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                    transition={"transform 0.3s ease-in-out"}
                  />
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link to={"/privacy"}>
                    <Text>Privacy Policy</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/refund"}>
                    <Text>Refund & Cancellation Policy</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/return"}>
                    <Text>Return Policy</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/shipping"}>
                    <Text>Shipping Policy</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/terms"}>
                    <Text>Terms of Use</Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </HStack>
      <Button
        bg={"scarlet"}
        color={'#FFF'}
        cursor={"pointer"}
        fontSize={'14px'}
        fontWeight={'600'}
        borderRadius={'10px'}
        onClick={() => setIsCustomer(!isCustomer)}
        _hover={{bg: 'scarlet'}}
      >
        {isCustomer ? "Become a Seller" : "Customer Site"}
      </Button>
    </HStack>
  );
};

export default Navbar;
