import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Return = () => {
  return (
    <VStack px={{ base: 10, tablet: "104px" }} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Return Policy</title>
      </Helmet>
      <Heading fontSize={{ base: "24px", tablet: "36px" }} fontWeight={"600"}>
        Return Policy
      </Heading>
      <Image mt={20} src="/images/return-hero.svg" alt="hero" />
      <VStack
        px={{ base: 6, tablet: 16 }}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <Text color={"doveGray"} fontSize={{ base: "16px", tablet: "22px" }}>
          At cakelaya, we understand the importance of customer satisfaction and
          are committed to providing high-quality products. Please read our
          Return Policy carefully regarding food items.
        </Text>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"600"}
            color={"black"}
          >
            Perishable Nature of Food Items
          </Heading>
          <Text color={"doveGray"} fontSize={{ base: "16px", tablet: "22px" }}>
            Due to the perishable nature of food items, once they are delivered,
            they cannot be returned or replaced. We prioritise the freshness and
            quality of our products and cannot accept returns for items that
            have already been delivered.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"600"}
            color={"black"}
          >
            Product Quality Issues
          </Heading>
          <Text color={"doveGray"} fontSize={{ base: "16px", tablet: "22px" }}>
            If you encounter any issues with the quality of the product you have
            received, we encourage you to contact us immediately. Please reach
            out to our customer support team to raise a refund query. We take
            product quality concerns seriously and will work with you to address
            the issue promptly.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading
            fontSize={{ base: "16px", tablet: "22px" }}
            fontWeight={"600"}
            color={"black"}
          >
            Contact Us
          </Heading>
          <Text color={"doveGray"} fontSize={{ base: "16px", tablet: "22px" }}>
            If you have any questions or concerns regarding our Refund &
            Cancellation Policy, or if you need assistance with your order,
            please do not hesitate to contact us at our official email ID. Our
            customer support team is here to help address any inquiries or
            issues you may have.
          </Text>
        </VStack>
        <Text color={"doveGray"} fontSize={{ base: "16px", tablet: "22px" }}>
        Thank you for choosing cakelaya. We appreciate your understanding of our Return Policy regarding food items and look forward to serving you again in the future.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Return;
