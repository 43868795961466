import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Aim = () => {
  return (
    <VStack px={{base: 10, tablet: "104px"}} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Aim & Vision</title>
      </Helmet>
      <Heading fontSize={{base: "24px", tablet: "36px"}} fontWeight={"600"}>
        Aim & Vision
      </Heading>
      <Image mt={20} src="/images/aim-hero.svg" alt="hero" />
      <VStack
        px={{base: 6, tablet: 16}}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Solving Tier 2 and Tier 3 Food Delivery Woes
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          Our primary goal is to revolutionize food delivery in Tier 2 and Tier 3 cities, where existing options often fall short. We're here to bridge the gap and bring delicious food right to your doorstep.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Eliminating Overpriced Options
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Existing marketplace options are not just inefficient; they're also
            notorious for overpricing. We're determined to change that
            narrative by offering affordable solutions without compromising on
            quality.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Empowering Local Restaurants, Building a Fair Marketplace
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            By partnering with small and local restaurants, we're creating a level
            playing field where everyone benefits. No more domination by big
            players – just fair opportunities for all.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Win-Win for Customers and Shopkeepers
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Our mission is simple: to ensure both customers and local
            shopkeepers reap the rewards. With fair pricing and quality cakes,
            everyone wins.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Supporting Post-Lockdown Recovery
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            In the wake of lockdown losses, we believe in leveraging technology
            to uplift struggling businesses. Our platform not only benefits
            customers but also supports local vendors in their recovery journey.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Freshness Guaranteed, Quality Assured
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Your health and satisfaction are our top priorities. That's why all
            our cakes are freshly prepared, ensuring you enjoy the
            best quality every time.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Convenience Redefined
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          Imagine indulging in the finest food from the comfort of your home. With us, convenience and affordability go hand in hand – it's a win-win for everyone involved.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Experience the Difference
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Give our services a try and discover a hassle-free food delivery
            experience like never before.
          </Text>
        </VStack>
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
        Ready to join the revolution? Let's indulge in convenience, affordability, and deliciousness together with us!
        </Text>
      </VStack>
    </VStack>
  );
};

export default Aim;
