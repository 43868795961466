import { useContext } from "react";
import {
  VStack,
  HStack,
  Text,
  Flex,
  Button,
  Image,
  Heading,
  Highlight,
  useMediaQuery,
} from "@chakra-ui/react";
import { CustomerContext } from "../CustomerContext";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import Slides from "../components/Slides";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const Home = () => {
  const { isCustomer, setIsCustomer } = useContext(CustomerContext);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <VStack mt={{ base: 10, tablet: 20 }} gap={0} overflow={"hidden"}>
      <Helmet>
        <title>Cakeलाया? - Home</title>
      </Helmet>
      <VStack
        alignItems={{ base: "center", tablet: "start" }}
        px={{ base: 4, tablet: "118px" }}
      >
        <Image
          w={{ base: "274px", md: "271px", tablet: "526px" }}
          src={
            isCustomer
              ? "/images/customer-hero.svg"
              : "/images/merchant-hero.svg"
          }
          alt="Hero-1"
          display={{ base: "block", tablet: "none" }}
        />
        <Flex
          alignItems={"start"}
          gap={20}
          flexDir={{ base: "column", tablet: "row" }}
        >
          <VStack
            alignItems={{ base: "center", tablet: "flex-start" }}
            mt={10}
            gap={6}
          >
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <Heading
                fontWeight={"700"}
                fontSize={{ base: "32px", tablet: "60px" }}
                color={"saltBox"}
                textAlign={{ base: "center", tablet: "start" }}
              >
                <Highlight
                  query={isCustomer ? "Perfect Food" : "Sell Food"}
                  styles={{ color: "scarlet" }}
                >
                  {isCustomer
                    ? "Perfect Food for All Occasions"
                    : "Sell Food at Your Fingertips"}
                </Highlight>
              </Heading>
              <Text
                fontSize={{ base: "12px", tablet: "14px" }}
                fontWeight={"700"}
                color={"saltBox"}
                textAlign={{ base: "center", tablet: "start" }}
                mt={4}
              >
                {isCustomer
                  ? "Indulge in unforgettable moments with our exquisite food items tailored for your daily cravings. Elevate every craving with our perfect blend of taste and elegance."
                  : "Sell your finest food items at  best-in-town price!"}
              </Text>
            </motion.div>
            <Link
              to={
                isCustomer
                  ? "https://play.google.com/store/apps/details?id=com.cakelaya&hl=en_US"
                  : "https://play.google.com/store/apps/details?id=com.cakelaya.merchant"
              }
            >
              <Button
                bg={"scarlet"}
                color={"#FFF"}
                cursor={"pointer"}
                borderRadius={"10px"}
                fontWeight={{ base: "600", tablet: "700" }}
                fontSize={{ base: "12px", tablet: "14px" }}
                _hover={{ bg: "scarlet" }}
                py={{ base: 2.5, tablet: "19px" }}
                px={{ base: 3, tablet: 6 }}
                h="auto"
              >
                Get Started
              </Button>
            </Link>
          </VStack>
          
            <Image
              w={{ base: "274px", md: "271px", tablet: "526px" }}
              src={
                isCustomer
                  ? "/images/customer-hero.svg"
                  : "/images/merchant-hero.svg"
              }
              alt="Hero-1"
              display={{ base: "none", tablet: "block" }}
            />
        </Flex>

        <Flex
          alignItems={{ base: "center", tablet: "start" }}
          gap={10}
          w={{ base: "full", tablet: "713px" }}
          mt={{ base: 10, tablet: -16 }}
          flexDir={{ base: "column", tablet: "row" }}
        >
          <Image
            src={
              isCustomer
                ? "/images/customer-hero-2.svg"
                : "/images/merchant-hero-2.svg"
            }
            alt="Hero-2"
            w={{ base: "287px", tablet: "331px" }}
          />
          <VStack alignItems={{ base: "center", tablet: "start" }}>
            <Text
              color={"saltBox"}
              fontWeight={"700"}
              fontSize={{ base: "12px", tablet: "14px" }}
              pb={1}
              position="relative"
              width="100%"
              textAlign={"center"}
              _after={{
                content: '""',
                position: "absolute",
                bottom: 0,
                left: isMobile ? "44%" : "0.3",
                width: isMobile ? "56px" : "16%",
                height: "1px",
                backgroundColor: "#6D6875",
              }}
            >
              {isCustomer ? "Best in Town" : "Sell Online"}
            </Text>

            <VStack
              gap={2}
              alignItems={{ base: "center", tablet: "start" }}
              mt={4}
            >
              <Heading
                color={"scarlet"}
                fontWeight={{ base: "700", tablet: "600" }}
                fontSize={{ base: "20px", tablet: "2xl" }}
              >
                {isCustomer ? "Amazing Products" : "Become Seller"}
              </Heading>
              <Text
                textAlign={{ base: "center", tablet: "start" }}
                fontSize={{ base: "12px", tablet: "14px" }}
                color={"saltBox"}
              >
                {isCustomer
                  ? "Amazing products that would leave you speechless, each one a delightful masterpiece of taste and presentation."
                  : "Join our platform and expand your reach, effortlessly connecting with a vast online audience eager for your products."}
              </Text>
            </VStack>

            <HStack
              h="auto"
              w={{ base: "full", tablet: "435px" }}
              py={{ base: 2.5, tablet: 6 }}
              px={{ base: 5, tablet: 8 }}
              bg={"#FFF"}
              ml={{ base: 0, tablet: "-100px" }}
              mt={8}
              borderRadius={"10px"}
              justifyContent={"space-between"}
              boxShadow={{
                base: "0px 2px 14px rgba(47, 65, 88, 0.2)",
                tablet: "0px 30px 80px rgba(47, 65, 88, 0.2)",
              }}
            >
              <Text
                fontSize={{ base: "12px", tablet: "16px" }}
                color={"saltBox"}
              >
                {isCustomer ? "Order Right Now" : "Sign Up Now"}
              </Text>
              <Link
                to={
                  isCustomer
                    ? "https://play.google.com/store/apps/details?id=com.cakelaya&hl=en_US"
                    : "https://play.google.com/store/apps/details?id=com.cakelaya.merchant"
                }
              >
                <Button
                  bg={"scarlet"}
                  color={"#FFF"}
                  borderRadius={"10px"}
                  fontSize={{ base: "12px", tablet: "14px" }}
                  fontWeight={"600"}
                  py={{ base: 4, tablet: 5 }}
                  px={{ base: 4, tablet: 6 }}
                  h="auto"
                  w="auto"
                  _hover={{ bg: "scarlet" }}
                >
                  Download Our App
                </Button>
              </Link>
            </HStack>
          </VStack>
        </Flex>
      </VStack>

      <Flex
        alignItems={{ base: "center", tablet: "start" }}
        justifyContent={"space-between"}
        mt={40}
        h="347px"
        px={{ base: 4, tablet: "118px" }}
        flexDir={{ base: "column", tablet: "row" }}
      >
        <Image
          h={{ base: "195px", tablet: "347px" }}
          src={
            isCustomer
              ? "/images/customer-hero-3.png"
              : "/images/merchant-hero-3.png"
          }
          alt="Hero"
          display={{ base: "block", tablet: "none" }}
          mb={6}
        />

        <VStack
          alignItems={{ base: "center", tablet: "start" }}
          w={{ base: "full", tablet: "42%" }}
          position={"relative"}
          h="auto"
        >
          <VStack alignItems={{ base: "center", tablet: "start" }} gap={6}>
            <Heading
              fontWeight={"700"}
              fontSize={{ base: "20px", tablet: "44px" }}
              color={"saltBox"}
              textAlign={{ base: "center", tablet: "left" }}
            >
              <Highlight
                query={isCustomer ? "Crafting" : "Sell"}
                styles={{ color: "scarlet" }}
              >
                {isCustomer
                  ? "Crafting freshly cooked food just for you!"
                  : "Sell freshly cooked food in your city!"}
              </Highlight>
            </Heading>
            <Text
              color={"saltBox"}
              textAlign={{ base: "center", tablet: "left" }}
              fontSize={{ base: "12px", tablet: "20px" }}
            >
              We're cakelaya a team of innovative thinkers driven by a shared vision to revolutionise the way people consume food. Here's a glimpse into our story. 
            </Text>
          </VStack>

          <Link to={"/about"}>
            <HStack color={"scarlet"} mt={{ base: 4, tablet: 10 }} bottom={2}>
              <Text
                fontWeight={{ base: "700", tablet: "400" }}
                fontSize={{ base: "12px", tablet: "20px" }}
              >
                Read More
              </Text>
              <Image
                w={{ base: "28px", tablet: "48px" }}
                src={"/images/right-arrow.svg"}
                alt="arrow"
              />
            </HStack>
          </Link>
        </VStack>

        <Image
          h={{ base: "195px", tablet: "347px" }}
          src={
            isCustomer
              ? "/images/customer-hero-3.png"
              : "/images/merchant-hero-3.png"
          }
          alt="Hero"
          display={{ base: "none", tablet: "block" }}
        />
      </Flex>

      <VStack
        mt={{ base: 24, tablet: 40 }}
        w="full"
        px={{ base: 4, tablet: "118px" }}
      >
        <VStack gap={{ base: 2, tablet: 6 }}>
          <Heading
            fontSize={{ base: "20px", tablet: "32px" }}
            fontWeight={"700"}
            color={{ base: "saltBox", tablet: "mineShaft" }}
          >
            <Highlight query={"Offer?"} styles={{ color: "scarlet" }}>
              What we Offer?
            </Highlight>
          </Heading>
          <Text fontSize={{ base: "12px", tablet: "16px" }} color={"gray"}>
            Your favourite food delivery partner!
          </Text>
        </VStack>

        <Flex
          mt={{ base: 10, tablet: 20 }}
          alignItems={{ base: "center", tablet: "baseline" }}
          w="full"
          justifyContent={"space-between"}
          flexDir={{ base: "column", tablet: "row" }}
          gap={10}
        >
          <Card
            img={
              isCustomer
                ? "/images/customer-card-1.svg"
                : "/images/merchant-card-1.svg"
            }
            title={isCustomer ? "Easy Ordering" : "Boost Your Sales"}
            desc={
              isCustomer
                ? "Seamless Ordering Via Our App!"
                : "Boost Your Earnings: Partner With Us!"
            }
            isCustomer={isCustomer}
          />
          <Card
            img={
              isCustomer
                ? "/images/customer-card-2.svg"
                : "/images/merchant-card-2.svg"
            }
            title={isCustomer ? "Fast Delivery" : "Grow Your Business"}
            desc={
              isCustomer
                ? "Swift Dispatch: Get It Quick!"
                : "Skyrocket Your Sales & Income With Us!"
            }
            isCustomer={isCustomer}
          />
          <Card
            img={
              isCustomer
                ? "/images/customer-card-3.svg"
                : "/images/merchant-card-3.svg"
            }
            title={isCustomer ? "Trending Products" : "Promote Your Shop"}
            desc={
              isCustomer
                ? "Trendy Products, Unbeatable Prices!"
                : "Expand Your Reach: Discover Our App!"
            }
            isCustomer={isCustomer}
          />
        </Flex>
      </VStack>

      <VStack mt={{ base: 20, tablet: 40 }} bg={"fairPink"} w="full" py={20}>
        <Heading
          color={"mineShaft"}
          fontWeight={"700"}
          fontSize={{ base: "20px", tablet: "36px" }}
        >
          What's Next?
        </Heading>
        <Text
          color={"emperor"}
          mt={4}
          w={{ base: "80%", tablet: "45%" }}
          textAlign={"center"}
          fontSize={{ base: "12px", tablet: "16px" }}
        >
          {isCustomer
            ? "At cakelaya, we prioritize customer satisfaction and strive to provide a seamless experience for all our customers!"
            : "At cakelaya, we prioritize merchant satisfaction and strive to provide a seamless experience for all our merchant!"}
        </Text>
        <Link to="mailto:support@cakelaya.com">
          <Button
            fontSize={{ base: "12px", tablet: "18px" }}
            bg={"scarlet"}
            color={"#FFF"}
            mt={10}
            py={{ base: 3, tablet: 4 }}
            h="auto"
            px={{ base: 4, tablet: 6 }}
          >
            Contact Us Now
          </Button>
        </Link>
      </VStack>

      <Slides />

      <HStack
        py={{ base: 8, tablet: 0 }}
        bg={"chablis"}
        px={{ base: 8, tablet: 32 }}
        w="full"
        justifyContent={"space-between"}
      >
        <VStack
          alignItems={{ base: "center", tablet: "start" }}
          w={{ base: "full", tablet: "34%" }}
        >
          <VStack alignItems={{ base: "center", tablet: "left" }}>
            <Heading
              color={"saltBox"}
              fontSize={{ base: "20px", tablet: "44px" }}
            >
              <Highlight query={"Download"} styles={{ color: "scarlet" }}>
                Download Our App
              </Highlight>
            </Heading>
            <Text
              mt={4}
              color={"emperor"}
              fontSize={{ base: "15.66px", tablet: "20px" }}
              textAlign={{ base: "center", tablet: "left" }}
            >
              {isCustomer
                ? "Access our app for seamless ordering experience!"
                : "Access our app for seamless selling experience!"}
            </Text>
          </VStack>

          <HStack mt={{ base: 10, tablet: 20 }}>
            <Link
              to={
                isCustomer
                  ? "https://play.google.com/store/apps/details?id=com.cakelaya&hl=en_US"
                  : "https://play.google.com/store/apps/details?id=com.cakelaya.merchant"
              }
            >
              <Image
                src="/images/play-store.svg"
                alt="Play Store"
                cursor={"pointer"}
              />
            </Link>
            <Image src="/images/apple-store.svg" alt="Apple Store" />
          </HStack>
        </VStack>

        <VStack
          w={{ base: "full", tablet: "45%" }}
          position={"relative"}
          h="707px"
          display={{ base: "none", tablet: "flex" }}
        >
          <Image
            position={"absolute"}
            top={0}
            right={0}
            src={
              isCustomer
                ? "/images/mobile-1.svg"
                : "/images/merchant-mobile-1.svg"
            }
          />
          <Image
            position={"absolute"}
            bottom={0}
            left={0}
            src={
              isCustomer
                ? "/images/mobile-2.svg"
                : "/images/merchant-mobile-2.svg"
            }
          />
        </VStack>
      </HStack>
    </VStack>
  );
};

export default Home;
