import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Refund = () => {
  return (
    <VStack px={{base: 10, tablet: "104px"}} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Refund & Cancellation Policy</title>
      </Helmet>
      <Heading textAlign={'center'} fontSize={{base: "24px", tablet: "36px"}} fontWeight={"600"}>
        Refund & Cancellation Policy
      </Heading>
      <Image mt={20} src="/images/refund-hero.svg" alt="hero" />
      <VStack
        px={{base: 6, tablet: 16}}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          At cakelaya, we prioritise customer satisfaction and strive to provide
          a seamless experience for all our customers. Please read the following
          Policy regarding Refunds, Cancellations, and Updates to your order.
        </Text>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Cancellation Policy
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Currently, the option to cancel your order is not available.
            Therefore, we kindly request all customers to place their orders
            with utmost care. Once an order is placed, it cannot be cancelled.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Refund Policy
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            If you need to request a refund due to a genuine reason, we process
            refunds after verification. However, please note that a standard
            deduction of 10% of the order value will be applied to all refund
            requests initiated by users. The remaining amount will be refunded
            to your original payment method within 1-2 business days.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Contact Us
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            If you have any questions or concerns regarding our Refund &
            Cancellation Policy, or if you need assistance with your order,
            please do not hesitate to contact us at our official email ID. Our
            customer support team is here to help address any inquiries or
            issues you may have.
          </Text>
        </VStack>
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          Thank you for choosing cakelaya, We look forward to serving you again
          in the future.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Refund;
