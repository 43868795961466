import React from "react";
import { VStack, Image, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Shipping = () => {
  return (
    <VStack px={{base: 10, tablet: "104px"}} mt={16} pb={20}>
      <Helmet>
        <title>Cakeलाया? - Shipping Policy</title>
      </Helmet>
      <Heading fontSize={{base: "24px", tablet: "36px"}} fontWeight={"600"}>
        Shipping Policy
      </Heading>
      <Image mt={20} src="/images/shipping-hero.svg" alt="hero" />
      <VStack
        px={{base: 6, tablet: 16}}
        py={12}
        mt={10}
        gap={10}
        alignItems={"start"}
        borderRadius={"23px"}
        boxShadow={"0px 2px 20px rgba(0,0,0,0.1)"}
      >
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          At cakelaya, we serve as an online platform that connects customers
          with local bakers. Please review our Shipping Policy below.
        </Text>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Shipping Timeline
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          It varies for each shop.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Third-Party Bakery Vendors
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            All food items available on our app or website are delivered to you by
            third-party partner restaurants. As such, cakelaya holds no
            responsibility for the delivery process, including but not limited
            to shipping delays, damages during transit, or delivery issues.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Responsibility Disclaimer
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            While we strive to partner with reliable vendors to ensure a
            smooth delivery experience, cakelaya, acts solely as an intermediary
            between customers and partner restaurants. Therefore, any issues related
            to shipping, delivery, or product quality will be addressed directly
            by the respective restaurant partner after consultation.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Customer Support
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            Should you encounter any issues or have concerns regarding the
            shipping or delivery of your order, we encourage you to contact us
            at our official email ID. Our customer support team is available to
            assist you and will do their best to facilitate communication
            between you and the relevant partner to resolve the issue.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Communication
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
            We value transparency and strive to keep our customers informed
            throughout the ordering and delivery process. If there are any
            changes or updates regarding your order, including shipping status
            or delivery estimates, we will communicate them to you promptly via
            in-app notifications or email.
          </Text>
        </VStack>
        <VStack gap={0} alignItems={"start"}>
          <Heading fontSize={{base: "16px", tablet: "22px"}} fontWeight={"600"} color={"black"}>
            Contact Us
          </Heading>
          <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
          If you have any questions or require further assistance regarding our Shipping Policy or any other aspect of your order, please do not hesitate to reach out to us at our official email ID. We are committed to providing excellent customer service and ensuring your satisfaction with every purchase.
          </Text>
        </VStack>
        <Text color={"doveGray"} fontSize={{base: "16px", tablet: "22px"}}>
        Thank you for choosing cakelaya. We appreciate your understanding of our Shipping Policy and look forward to serving you again in the future.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Shipping;
