import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/policy/Privacy";
import Refund from "./pages/policy/Refund";
import Return from "./pages/policy/Return";
import Shipping from "./pages/policy/Shipping";
import About from "./pages/about/About";
import Aim from "./pages/about/Aim";
import Default from "./layouts/Default";
import Work from "./pages/about/Work";
import Values from "./pages/about/Values";
import Terms from './pages/policy/Terms'
import { CustomerContext } from "./CustomerContext";

const App = () => {
  const [isCustomer, setIsCustomer] = useState(true);
  return (
    <CustomerContext.Provider value={{ isCustomer, setIsCustomer }}>
      <Routes>
        <Route path="/" element={<Default />}>
          <Route index element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/return" element={<Return />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/about" element={<About />} />
          <Route path="/aim" element={<Aim />} />
          <Route path="/work" element={<Work />} />
          <Route path="/values" element={<Values />} />
          <Route path="/terms" element={<Terms />} />
        </Route>
      </Routes>
    </CustomerContext.Provider>
  );
};

export default App;
